import {
  FANS,
  FINANCES,
  SALES,
  ACCOUNTS,
  THIRD_PARTY,
  PROFILE_BUILDER,
  UNRESTRICTED_ACCESS,
} from './permissions';

const INTERNAL_ONLY = [FANS, FINANCES, SALES, ACCOUNTS];

// IMPORTANT NOTE !!!!!
// this permission name needs to match the entityname
// IMPORTANT NOTE !!!!!

export const campaign = [FANS, ACCOUNTS];
export const competitionRound = [FANS, ACCOUNTS];
export const competitions = [FANS];
export const events = [FANS, ACCOUNTS];
export const consumerEvents = [FANS, ACCOUNTS];
export const calendarContent = [FANS, ACCOUNTS];
export const home = [UNRESTRICTED_ACCESS];
export const game1matches = [FANS, ACCOUNTS];
export const gameLeagueRoundPrize = [FANS, ACCOUNTS];
export const gameLeagueMonthPrize = [FANS, ACCOUNTS];
export const gameShareCopy = [FANS, ACCOUNTS];
export const gamePrizeMember = [FANS, ACCOUNTS];
export const gamePrize = [FANS, ACCOUNTS];
export const game = [FANS, ACCOUNTS];
export const fixture = [FANS, ACCOUNTS]; // gameFixtures
export const trivias = [FANS, ACCOUNTS];
export const adverts = INTERNAL_ONLY;
export const reactivePrize = [FANS, ACCOUNTS];
export const gameBatchPush = [FANS, ACCOUNTS];
export const groupCodes = [FINANCES, SALES, ACCOUNTS];
export const venues = [...INTERNAL_ONLY, THIRD_PARTY, PROFILE_BUILDER];
export const venueMember = INTERNAL_ONLY;
export const venueCompleterBatch = [ACCOUNTS, PROFILE_BUILDER];
export const venueCompleterVenue = [ACCOUNTS, PROFILE_BUILDER];
export const venueFinderDataScopes = INTERNAL_ONLY;
export const league = [FANS, ACCOUNTS];
export const members = [FANS, ACCOUNTS];
export const news = [FANS];
export const offer = [SALES, FANS, ACCOUNTS];
export const offerCodes = [SALES, FANS, ACCOUNTS];
export const pageTvGuide = [FANS];
export const parameter = [SALES, FANS, ACCOUNTS];
export const permission = [];
export const pubPortalCode = [FINANCES];
export const quiz = [FANS, ACCOUNTS];
export const quizAnswer = [FANS, ACCOUNTS];
export const quizMember = [FANS, ACCOUNTS];
export const quizQuestion = [FANS, ACCOUNTS];
export const quizRound = [FANS, ACCOUNTS];
export const quizBatchPush = [FANS, ACCOUNTS];
export const pages = [FANS]; // seoPages
export const socialMediaPostTheme = [];
export const sports = [FANS];
export const team = [FANS];
export const tier = [FINANCES];
export const venueGroup = [SALES, FINANCES, ACCOUNTS];
export const reports = [SALES, ACCOUNTS, FANS, FINANCES];
export const importVenues = [SALES, ACCOUNTS];
export const importEvents = [SALES, ACCOUNTS];
export const countryChannel = [SALES, ACCOUNTS, FANS, FINANCES];
export const faq = INTERNAL_ONLY;
export const pills = [SALES, FANS, FINANCES];
export const quizThemes = INTERNAL_ONLY;
export const venueSocialMediaCompetitionCountry = INTERNAL_ONLY;
export const homepage = [FANS];
export const homepageSections = [FANS];
export const channels = [FANS];
export const bigFixtures = [FANS];
export const blogArticle = [FANS];
export const sportPriority = [FANS];
export const compeitionPriority = [FANS];
export const batch = [FANS];
export const errorPage = INTERNAL_ONLY;
export const mandates = [FINANCES, ACCOUNTS, SALES];
export const URLShortener = [SALES, FANS, FINANCES, ACCOUNTS];
export const deeplinks = INTERNAL_ONLY;
export const assetGroup = [ACCOUNTS];
export const bustCache = [SALES, FANS, FINANCES, ACCOUNTS];
